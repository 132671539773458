$en-font: "Courier", Helvetica, sans-serif;

$default-font: "游ゴシック", YuGothic, "小塚ゴシック Pro R", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;

$ryumin-R: "リュウミン B-KL";
$tsukugo-R: "FOT-筑紫ゴシック Pr5 R";
$tsukumin-R: "F+UD-筑紫明朝 R";

$base-font-l: "FOT-筑紫ゴシック Pr5 L", TsukuGoPro-L, $default-font;
$base-font-r: "FOT-筑紫ゴシック Pr5 R", TsukuGoPro-R, $default-font;
$base-font-m: "FOT-筑紫ゴシック Pr5 M", TsukuGoPro-M, $default-font;
$base-font-d: "FOT-筑紫ゴシック Pr5 D", TsukuGoPro-D, $default-font;
$base-font-b: "FOT-筑紫ゴシック Pr5 B", TsukuGoPro-B, $default-font;
$base-font-e: "FOT-筑紫ゴシック Pr5 E", TsukuGoPro-E, $default-font;
$base-font-h: "FOT-筑紫ゴシック Pr5 H", TsukuGoPro-H, $default-font;
$base-font-u: "FOT-筑紫ゴシック Pr5 U", TsukuGoPro-U, $default-font;

$base-font: $base-font-r;

body{
	-webkit-font-smoothing: antialiased;
}
