//_table.scss

table{
	&{
		border:none;
	}
	tr:nth-of-type(even) {
		background: transparent;
	}
	th{

	}
	th,td{

	}
	@media #{$small-only}{
		main &{
			thead{
				display: none;
			}
			th,td{
				display: block;
				width: 100%;
				padding: 0 0.7em;
			}
		}
	}
}
