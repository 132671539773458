//var
$base-line: rem-calc(21);

//settings/layout

$main-nav-align: right;
$main-nav-font-color: $white;
$main-nav-font-family: $en-font;
$main-nav-font-style: italic;
$main-nav-font-weight: 600;
$main-nav-font-size: rem-calc(24);
