//_welcart.support.scss

//calendar
.usces_calendar{
	margin-bottom: $base-line;
	font-family: $en-font;
	letter-spacing: 0;
	caption{
		margin-bottom: $base-line / 2;
		text-align: left;
		letter-spacing: 0.05rem;
		line-height: $base-line;
		font-size: rem-calc(12);
		font-family: $ryumin-R;
		font-weight: normal;
	}
	thead{
		background-color: transparent;
	}
	th{
		font-size: rem-calc(11);
		padding: 0;
		line-height: $base-line;
		text-align:center;
	}
	td{
		padding: 0.4em;
		text-align:center;
		font-size: rem-calc(10);
		&.businessday{
			background-color: $tuatara;
			border: 1px solid $white;
			color: $white;
		}
	}
}
.business_days_exp_box{
	background-color: $tuatara;
}
.calendar{
	font-size: rem-calc(10);
}


//
.usccart-navi{
	.current{
		text-decoration: underline;
	}
}
.usccart-error-message{
	font-size: rem-calc(12);
	line-height: $base-line;
	color: $error-color;
}
.form-table{
	width: 100%;
	margin-bottom: $base-line;
	border-spacing: 0;
	th{
		vertical-align: top;
		white-space: nowrap;
		font-size: rem-calc(12);
	}
	td{
		//padding-bottom: $base-line / 2;
		font-size: rem-calc(12);
	}
}
.customer-form-table{
	th{
		width: 20%;
		padding: 0.7em;
		text-align: right;
		em{
			font-style: normal;
			color: $error-color;
		}
	}
	td{
		padding-bottom: $base-line / 2;
		input{
			margin-bottom: 0;
		}
		label{
  			margin-top: 0.7em;
  			font-size: rem-calc(12);
		}
		input[type="checkbox"]+label, input[type="radio"]+label{
			margin-left: 0.25rem;
		}
		label input{
			margin-right: 0.5rem;
		}
	}
	//input個別設定
	#name1,#name2,#name3,#name4{
		display: inline-block;
		width: 75%;
		margin: 0 0.25em;
	}
	#zipcode{ max-width: 150px; }
	#delivery_pref,
	#customer_pref{ max-width: 100px; }
	#tel{ max-width: 200px; }
	#fax{ max-width: 200px; }
	#delivery_method_select{ max-width: 200px; }
	#delivery_time_select{ max-width: 200px; }
	#note{ height: $base-line*5; }
}
.confirm-form-table{
	th{
		padding: 0.5em;
		border-bottom: 1px solid;
		text-align: left;
	}
	td{
		padding: 0.5em;
		border-bottom: 1px solid;
	}
	.blank-cell{
		border: none;
	}
	.aright{
		text-align: right;
	}
	.ttl h3{
		margin: $base-line 0 0;
	}
	//input個別設定
	em.tax{
		font-style: normal;
		font-size: rem-calc(11);
	}
}

.usccart-send{
	//padding: $base-line 0;
	text-align: right;
}
