//wordpress support
@media #{$small-only} {
	html.touch{
		margin-top: 0 !important;
	}
	#wpadminbar {
		display: none;
		position: fixed !important;
	}
}

//guide
body{
	letter-spacing: 0.125em;
}
em{
	font-style: normal;
}
a.current{
	// font-family: $base-font-d;
	// font-weight: bold;
	text-decoration: underline;
}
.circle-text{
	display: inline-block;
	width: 22px;
	border: 1px solid;
	border-radius: 11px;
	letter-spacing: 0;
	line-height: 20px;
	text-align: center;
	font-size: 11px;
}
.soldout{
	font-family: $base-font-b;
	font-size: rem-calc(10);
	color: #122773;//navy
}



.icon--menu{
	-webkit-transition: padding 300ms;
	     -o-transition: padding 300ms;
	        transition: padding 300ms;
	$icon-color: #000;
	$menu-bar-width: 18px;
	$menu-bar-height: 4px;
	float: right;
	height: 16px;
	&{
		position: relative;
		width: $menu-bar-width;
		margin: 16px 0;
		border-bottom: $menu-bar-height solid $icon-color;
	}
	&:before,
	&:after{
		-webkit-transition: margin 300ms;
		     -o-transition: margin 300ms;
		        transition: margin 300ms;
		content: "";
		display: block;
		height: $menu-bar-height;
		margin-bottom: 2px;
		background-color: $icon-color;
	}
	&.open{
		padding-top: 4px;
		margin-bottom: 20px;
		height: 8px;
		&:before,
		&:after{
			margin-bottom: -4px;
		}
	}
}
.icon--cart{
	img{
		width: 54px; height: auto;
		margin-top: -5px;
		padding: 16px;
		vertical-align: middle;
	}
}

$en-font: "Courier";
.en{
	letter-spacing: 0;
	font-family: $en-font;
}
.image--size_fit{
	width: 100%; height: auto;
}
.antipast{
	vertical-align: bottom;
	font-size: rem-calc(11);
}

//pieni huone original logo
.site-logo{
	&{
		padding: $base-line 0;
		text-align:center;
		line-height: 20px;
	}
	&:before{
		display: block;
		content: "";
		width: 14px; height: 14px;
		margin: 0 auto 18px;
		border: 4px solid;
		border-top-width: 7px;
		border-bottom-width: 0;
	}
	&.site-logo--type_oneline{
		padding: 0;
	}
	&.site-logo--type_oneline:before{
		display: inline-block;
		margin: 0 0.5em 0 0;
	}
	&__name{
		font-family: "Courier";
		&:after{
			display: block;
			content: "";
		}
		.site-logo--type_oneline &:after{
			display: inline-block;
		}
	}
	&__dot{
		margin-left: -0.15em;
		font-size: smaller;
		margin-right: -0.1em;
	}
	&__locate{
		font-size: rem-calc(11);
	}
}

//navigation
.global-nav{
	@media #{$small-only}{
		position: fixed; top: 0; left: 0;
		z-index: 256;
		background-color: $white;
	}
	&__lists{
	-webkit-transition: height 300ms;
	     -o-transition: height 300ms;
	        transition: height 300ms;
		position: absolute; top: 44px; right: 0;
		z-index: 1;
		overflow: hidden;
		height: 0;
		background-color: $white;
  		font-size: rem-calc(12);
		&.open{
			height: 155px;
		}
	}
	&__list a{
		display: inline-block;
		line-height: $base-line*1.5;
		white-space: nowrap;
		background-color: $white;
		@media #{$small-only}{
			padding: 0 0.5em;
		}
	}
}

//container layout
.container{
	margin: $base-line*10 $column-gutter*5 $base-line*0;
	body.subpage &{
		margin-top: $base-line*2;
	}
	@media #{$small-only} {
		margin: 0 $column-gutter*2;
	}
	&__header{
		//margin-bottom: $base-line*14;
		body.subpage &{
			margin-bottom: $base-line*2;
			height: $base-line*7;
		}
		@media #{$small-only} {
			margin-bottom: $base-line*1;
			body.subpage &{
				margin-bottom: $base-line*1;
				height: auto;
			}
		}
	}
	&__header-left{
		margin-top: $base-line*2;
		@media #{$small-only} {
			margin-top: 0;
		}
	}
	&__footer{
		margin-top: $base-line*7;
	}
}
#article-header,
.article-header{
	margin-top: -$base-line*3;
	margin-bottom: $base-line*2.5;
	.usces-cart &{
		margin-top: 0;
	}
	.onlineshop &{
		margin-top: 0;
		margin-left: 5%;
	}
	@media #{$small-only}{
		margin-top: 0;
	}
}

.cover{
	position: absolute;
	top: 0; left: 0;
	width: 100%;
	z-index: -1;
	&__inner{
		max-width: $row-width;
		margin: 0 auto;
	}
	&__image--align_left{
		left: 0;
	}
	&__image--align_right{
		right: 0;
	}
	&__image--align_center{
		left: 0;
		img{
			display: block;
			margin: $base-line*6 auto;
			width: 50%;
			height: auto;
		}
	}
	@media #{$small-only} {
		position: relative;
		&__image--align_center{
			img{
				margin: $base-line*2 auto;
			}
		}
	}
}

//
//	side
//
.side-title{
	font-size: rem-calc(12);
}
.calendar{
	margin-bottom: $base-line*3;
}
aside{

}

//
//	footer
//
.footer-columns{
	float: none;
	display: inline-block;
	margin-bottom: $base-line;
	vertical-align: bottom;
	white-space: nowrap;
	p{
		margin-bottom: 0;
	}
	.site-logo{
		margin-bottom: $base-line*2;
		text-align: left;
		@media #{$large-up}{
			text-align: center;
		}
	}
}
.footer-nav{
	margin: 0;
	list-style-type: none;
	&__list{
		font-size: rem-calc(12);
		&.parent10{
			margin-left: 1em;
		}
	}
	&__inner{
		margin: 0;
		padding-left:0.75em;
		list-style-type: none;
	}
}
.brand-nav{
	#post-list-nav &{
		margin-bottom: $base-line*1.25;
	}
	>li>a{
		color: darken($primary-color, 20%);
		&:hover{ opacity: 0.7; }
	}
	.footer-nav__inner a{
		color: darken($primary-color, 5%);
		&:hover{ opacity: 0.7; }
	}
}
.copyright{
	margin-top: $base-line*2;
}

//.item-list
.item-list{
	margin: 0 auto;
	html.flexbox &{
		display: -webkit-flexbox;
		display: -moz-flexbox;
		display: -ms-flexbox;
		display: -o-flexbox;
		display: flexbox;
	}
	&__thumb{
		position: relative;
		margin-bottom: $base-line;
		list-style-type: none;
		// &:before{
		// 	position: absolute; top: 50%; left: 50%;
		// 	content: " ∴ ";
		// 	opacity: 0.5;
		// 	animation: move 5s linear infinite;
		// }
	}
	img{
		transition: opacity 1s ease-out;
		opacity: 0;
	}
	img.show{
		opacity: 1;
		visibility: visible;
	}
}
.pageTitle.new,
.item-post__brand.new{
	display: inline;
	&:after {
		content: "・new";
		display: inline-block;
		background-color: #fff;
		color: $new-label-color;
		font-family: "FOT-筑紫ゴシック Pr5 B",TsukuGoPro-B,"游ゴシック",YuGothic,"小塚ゴシック Pro R","ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,sans-serif;
		font-size: 0.625rem;
	}
}
.item-post{
	&__main-photo{
		margin-bottom: $base-line / 3;
	}
	&__sub-photo{
		margin-bottom: $base-line / 3;
	}
	&__brand{
		margin-bottom: 0;
		line-height: $base-line;
	}
	&__title{
		margin-top: 0;
		margin-bottom: 17px;
		line-height: $base-line;
	}
}

//カートに入れる
.action-card{
	overflow: hidden;
	margin-bottom: $base-line/3;
	padding: $base-line/3;
	border-bottom: 1px solid #CCC;
	border-right: 1px solid #CCC;
	&__thumb{
		float: left;
		margin: 0 1rem 0 0;
		img{ width: 47px; }
	}
	&__title{
		margin: 0;
		line-height: $base-line;
	}
	&__price{
		display: inline-block;
		min-width: 7em;
		text-align: left;
		line-height: $base-line + $base-line / 3;
		font-family: $en-font;
		font-size: rem-calc(14);
	}
	&__quant{
		position: relative;
		display: inline-block;
		padding-right: 20px;
		font-size: rem-calc(12);
		input[type="text"].quantity,
		input[type="text"]{
			display: inline-block;
			width: 2.5rem; height: auto;
			margin:0 0.25em; padding: 0;
			line-height: 25px;
			text-align: center;
		}
		&__increase,
		&__decrease{
			$width: 20px;
			position: absolute;
			right: 0;
			display: inline-block;
			overflow: hidden;
			width: $width;
			background-color: $primary-color;
			border-radius: 3px;
			line-height: 13px;
			vertical-align: -8px;
			text-indent: $width;
			color: $primary-color;
			cursor: pointer;
			&:before{
				content: '';
				position: absolute;
				top: 50%; left: 50%;
				width: 0; height: 0;
				margin-left: -3px;
				border: 3px solid transparent;
  			}
		}
		&__increase{
			top: 0;
			&:before{
				border-bottom-color: $white;
				border-bottom-width: 4px;
				margin-top: -5px;
			}
		}
		&__decrease{
			bottom: 0;
			&:before{
				border-top-color: $white;
				border-top-width: 4px;
				margin-top: -1px;
			}
		}
	}
	&__button,
	&__add-cart{
		margin-left: $base-line/3;
		input[type="submit"] {
			vertical-align: 1px;
		}
	}
}

//archive-post
.archive-post{
	margin-bottom: $base-line*3;
}
article{
	position: relative;
	margin-bottom: $base-line*3;
	.date{
		position: absolute;
		top: 0; right: 100%;
		padding: 0 1.5em;
		@media #{$small-only} {
			position: static;
			padding: 0;
		}
	}
}
.post{
	&__footer{
		font-size: 12px;
		position: absolute;
		bottom: 30px;
		left: 105%;
	}
}

.breadcrumbs{
	overflow: inherit;
	margin-bottom: $base-line / 2;
	&:after{
		display: block;
		content: "";
		clear: both;
	}
	&__home span{
		position: relative;
		&:before,
		&:after{
			content: "";
			display: inline-block;
			position: absolute;
			bottom: 0;
			width: 0; height: 0;
		}
		&:before{
			left: -32px;
			border: 5px solid transparent;
			border-right-color: inherit;
		}
		&:after{
			left: -22px;
			border: 5px solid;
		}
	}
	@media #{$small-only}{
		&__home:before{
			margin-right: 0.75rem;
		}
		&>*{
			line-height: $base-line;
		}
	}
}

.pagenation{
	text-align: center;
	font-family: $en-font;
	font-size: rem-calc(12);
	.wp-pagenavi a, .wp-pagenavi span {
		border: none;
		letter-spacing: 0;
	}
	@media #{$small-only}{
		margin-bottom: $base-line*4;
		.wp-pagenavi a, .wp-pagenavi span {
			line-height: 44px;
		}
		.nextpostslink{
			display: block;
		}
	}
}
