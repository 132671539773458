//_form.scss

form h2{
	margin-top: 2rem;
}
//汎用cssへ
input[type]{
	border-radius: $global-radius;
}
.contact-button,
input[type="button"] ,
input[type="submit"] {
	padding: 0.5em 2em;
	border: none;
	border-radius: $global-radius;
	line-height: 1.5;
	cursor: pointer;
	&:hover{
		opacity: 0.8;
	}
}
.contact-button,
input[type="submit"] {
	background-color: #444;
	color: #FFF;
	&:hover{
		color: #FFF;
	}
}
input[type="text"].quantity{
	display: inline-block;
	width: 4em;
	text-align: right;
}

//買い物へ戻るを消す
#previouscart{
	display: none;
}
//次へのラベルを捕捉
.to_customerinfo_button:before{
	content:"注文手続き";
}

.container .mw_wp_form .error {
	font-weight: bold;
	font-style: normal;
  color: white;
}

input[type="text"][size="10"]{
	display: inline-block;
	width: 40%;
}

br + select,
input[type="hidden"] + select,
input[type="text"] + input[type="text"]{
	margin-top: -0.5rem;
}

fieldset{
	&{
		margin-bottom: 1rem;
		padding: 0;
		border: none;
	}
	legend{
		font-size: 12px;
		margin-bottom: 0.5rem;
	}
	p{
		margin-bottom: 0;
	}
	select,
	{
		margin-bottom: 0.5rem;
	}

}
