//color

$error-color: #852a2a;

$white       : #FFFFFF;
// $ghost       : #FAFAFA;
// $snow        : #F9F9F9;
// $vapor       : #F6F6F6;
// $white-smoke : #F5F5F5;
// $silver      : #EFEFEF;
// $smoke       : #EEEEEE;
// $gainsboro   : #DDDDDD;
// $iron        : #CCCCCC;
// $base        : #AAAAAA;
// $aluminum    : #999999;
// $jumbo       : #888888;
// $monsoon     : #777777;
$steel       : #666666;
// $charcoal    : #555555;
$tuatara     : #444444;
// $oil         : #333333;
$jet         : #222222;
// $black       : #000000;

$new-label-color: #FDCA00;
